<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'"
        :tipsContent="tipsContent"
        :radioIsCheck='radioIsCheck'
        @check="check"
		@logoff="logoff"
        @checkAgreement="checkAgreement"></Horizontal>
        <Vertical ref="Vertical" v-else
        :tipsContent="tipsContent"
        :radioIsCheck='radioIsCheck'
        @check="check"
		@logoff="logoff"
        @checkAgreement="checkAgreement"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/LogoffIndex/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/LogoffIndex/Vertical/Index.vue'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
               tipsContent:[
                   {
                       title:'① 账号处于安全状态',
                       content:'账号没有被盗、被封等风险'
                   },
                   {
                       title:'② 未结清订单',
                       content:'游戏内没有未支付订单'
                   },
                   {
                       title:'③ 账号解绑',
                       content:'该账号解除微信和手机号的授权与绑定关系'
                   }
               ],
               radioIsCheck:0
            }
        },
        methods:{
            check() {
                this.radioIsCheck = this.radioIsCheck===0?1:0
            },
            checkAgreement() {
                this.$router.push({
                    name:'LogoffAgreement'
                })
            },
			logoff() {
				if(this.radioIsCheck===1) {
					this.$api.user.logoff({user_id:this.$store.state.userId, token:this.$store.state.token}).then(res=>{
						if (res.data.code === 200) {
							this.$Toast({
								message: '注销成功！',
							})
							setTimeout(()=> {
								this.$common.logout()
							}, 2000)
						}
					})
				}else {
					this.$Toast({
						message: '请先勾选用户注销协议',
					})
				}
			}
        }
    }
</script>

<style>
</style>