<template>
	<div class="all-page">
		<TopNav title="账号注销" backPath="Mine"></TopNav>
		<div class="all-page-content-box">
			<div class="tips">
				注销账号前,我们将进行一些验证,已保证您的账
				号,财产安全
			</div>
			<div class="explain" v-for="(item, index) in tipsContent" :key="index">
				<div class="explain-title">
					{{item.title}}
				</div>
				<div class="explain-text">
					{{item.content}}
				</div>
			</div>
			<div class="bottom-box">
				<div class="agreement-radio">
					<div class="radio-check" @click="check">
						<div v-if="radioIsCheck===1"></div>
					</div>
					<div class="radio-text">
						阅读并同意
						<span class="radio-btn" @click="checkAgreement">《用户注销协议》</span>
					</div>
				</div>
				<div @click="logoff" class="logoff-btn" :class="radioIsCheck===1?'':'disable-btn'">
					狠 心 注 销
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TopNav from "@/components/Layout/TopNav.vue"
	export default {
		components: {
			TopNav
		},
		props: {
			tipsContent: Array,
			radioIsCheck: Number
		},
		data() {
			return {

			}
		},
		methods: {
			check(state) {
				this.$emit('check', state)
			},
			checkAgreement() {
				this.$emit('checkAgreement')
			},
			logoff() {
				this.$emit('logoff')
			}
		}
	}
</script>

<style scoped>
	.tips {
		font-size: 8.75px;
		font-weight: 400;
		padding-bottom: 6.25px;
	}

	.explain {
		padding-bottom: 7.5px;
	}

	.explain-title {
		font-size: 9px;
		font-weight: 500;
		padding-bottom: 1px;
	}

	.explain-text {
		font-size: 7.5px;
		font-weight: 400;
		color: #666666;
	}

	.bottom-box {
		padding-top: 16px;
	}

	.agreement-radio,
	.radio-check {
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.agreement-radio {
		padding-bottom: 4px;
	}

	.radio-check {
		width:6.5px;
		height: 6.5px;
		border-radius: 50%;
		border: 1px solid #B7B5B5;
        display: flex;
        align-items: center;
        justify-content: center;
	}

	.radio-check>div {
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: #16C5CE;
	}

	.radio-text {
		font-size: 8.75px;
		font-weight: 400;
		padding-left: 2px;
	}

	.radio-text>span {
		color: #16C5CE;
	}

	.logoff-btn {
		font-size: 10px;
		font-weight: 400;
		color: #FFFFFF;
		height: 26.25px;
		background: #16C5CE;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.2s;
	}

	.disable-btn {
		background-color: #B7B5B5;
		transition: 0.2s;
	}
</style>